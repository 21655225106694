<template>
  <v-overlay
    :value="true"
    :opacity=".8">
    <v-row
      class="fill-height d-flex align-center justify-center">
      <v-col
        class="text-center">
        <v-progress-circular
          indeterminate
          size="64"
          color="blue"
        />

        <div
          class="mt-4">
          {{ loadingText }}
        </div>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: 'FullScreenLoader',
  props: {
    loadingText: {
      type: String,
      default: 'Carregando...'
    }
  }
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
}
</style>
