export default {
  methods: {
    verifyExceptionWithStatusError400(codeMessage) {
      switch (codeMessage) {
        case 'invalid.externalfiledata.file':
          this.translatedCodeMessage = 'Arquivo carregado anteriormente.';
          break;

        case 'file.empty':
          this.translatedCodeMessage = 'O layout informado não existe.';
          break;

        case 'invalid.min.life':
          this.translatedCodeMessage = 'A vida mínima deve ser maior que 0.';
          break;

        case 'file.name.too.big':
          this.translatedCodeMessage = 'O nome do arquivo excede o máximo de 255 caracteres.';
          break;

        case 'invalid.document.file':
          this.translatedCodeMessage = 'Arquivo inválido.';
          break;

        case 'plan.data.not.found':
          this.translatedCodeMessage = 'É necessário informar o grupo financeiro, contrato e subcontrato para uma livre movimentação.';
          break;

        case 'holder.data.not.found':
          this.translatedCodeMessage = 'Para movimentações livres é necessário informar os dados do titular.';
          break;

        case 'ext_file_data.search_rules.not_founded':
          this.translatedCodeMessage = 'Regras para o tipo de arquivo selecionado não encontrado.';
          break;

        default:
          this.translatedCodeMessage = 'Ocorreu um erro Inesperado. Entre em contato com a equipe técnica';
          break;
      }
    },
    verifyExceptionWithStatusError404(codeMessage) {
      switch (codeMessage) {
        case 'file.data.not.found':
          this.translatedCodeMessage = 'O protocolo não foi encontrado pelo id fornecido.';
          break;

        default:
          this.translatedCodeMessage = 'Ocorreu um erro Inesperado. Entre em contato com a equipe técnica';
          break;
      }
    },
    verifyExceptionWithStatusError409(codeMessage, message) {
      switch (codeMessage) {
        case 'ext_file_data.search_rules.not_founded':
          this.translatedCodeMessage = 'Regras para o tipo de arquivo selecionado não encontrado.';
          break;

        case 'already.existing.document.movement':
          this.translatedCodeMessage = 'Configuração definida já existe.';
          break;

        case 'document.extfiledata.allowed':
          this.translatedCodeMessage = 'Erro ao realizar importação do arquivo';
          break

        case 'document.extfiledata.searching':
          this.translatedCodeMessage = 'Campos obrigatórios da consulta (data e/ou competencia) não preenchidos';
          break

        case 'id.download.ext_file_data.not.found':
          this.translatedCodeMessage = 'Arquivo não encontrado para download';
          break

        case 'invalid.document.file':
          this.translatedCodeMessage = 'Documento importado é invalido';
          break

        case 'extension.not.allowed':
          this.translatedCodeMessage = 'Extensão de arquivo não permitida, apenas xls, xlsx, csv, txt e/ou pdf';
          break

        case 'ext_file_data.file.allowed':
          this.translatedCodeMessage = 'Download não autorizado';
          break

        case 'configuration.not.found':
          this.translatedCodeMessage = 'Configuração não encontrada.';
          break

        case 'filter.not.found':
          this.translatedCodeMessage = 'Filtro não encontrado.';
          break;

        case 'report.layout.not.found':
          this.translatedCodeMessage = 'Layout do relatório não encontrado.';
          break;

        case 'config.not.active':
          this.translatedCodeMessage = 'Configuração não está ativa.';
          break

        case 'configuration.already.exists':
          this.translatedCodeMessage = 'Já existe uma configuração para o grupo financeiro.';
          break

        case 'registration.plate.not.found':
          this.translatedCodeMessage = 'O matrícula do titular não foi informado.';
          break;

        case 'already.existing.movement':
          this.translatedCodeMessage = 'Já existe uma movimentação registrada para o mesmo beneficiário.';
          break;

        case 'already.existing.beneficiary':
          this.translatedCodeMessage = 'Já existe um titular cadastrado no banco de dados com os dados inseridos.';
          break;

        case 'already.existing.dependent':
          this.translatedCodeMessage = 'Já existe um dependente cadastrado no banco de dados com os dados inseridos.';
          break;

        case 'already.layout.collector.informed':
          this.translatedCodeMessage = 'Já existe um layout com o coletor informado. Selecione outro Coletor para prosseguir';
          break;

        case 'layout.not.present':
          this.translatedCodeMessage = 'O layout informado não existe.';
          break;

        case 'collector.not.present':
          this.translatedCodeMessage = 'O layout informado não possui coletor vinculado.';
          break;

        case 'layout.divergent.extension':
          this.translatedCodeMessage = 'A extensão do arquivo informada difere da extensão configurada no layout.';
          break;

        case 'collector.not.valid':
          this.translatedCodeMessage = 'O tipo de configuração do coletor não é válido.';
          break;

        case 'already.existing.collector.configuration':
          this.translatedCodeMessage = 'Já existe uma configuração de integração do coletor para o tipo informado.';
          break;

        case 'already.existing.collector':
          this.translatedCodeMessage = 'Já existe um coletor com grupo financeiro, contrato ou sub-contrato informado.';
          break;

        case 'already.existing.movement.rule':
          this.translatedCodeMessage = 'Já existe uma regra de movimentação com a operadora, grupo financeiro, contrato ou sub-contrato informado.';
          break;

        case 'sdi.movementRecord.familyGroup.insert.duplicated':
          this.translatedCodeMessage = 'Já existe uma movimentação cadastrada para o mesmo Grupo Familiar.';
          break;

        case 'sdi.movementRecord.dependent.insert.duplicated':
        case 'sdi.movementRecord.dependent.delete.duplicated':
        case 'sdi.movementRecord.dependent.update.duplicated':
          this.translatedCodeMessage = 'Já existe uma movimentação cadastrada para o mesmo Dependente.';
          break;

        case 'sdi.movementRecord.familyGroup.delete.duplicated':
        case 'sdi.movementRecord.familyGroup.update.duplicated':
          this.translatedCodeMessage = 'Já existe uma movimentação cadastrada para o mesmo Titular.';
          break;

        case 'already.existing.batch.config':
          this.translatedCodeMessage = 'A configuração já existe para operadora e tipo de benefício.';
          break;

        case 'movement.record.parent.id.not.found':
          this.translatedCodeMessage = 'Erro ao buscar o id da movimentação de origem.';
          break;

        case 'movement.record.not.found':
          this.translatedCodeMessage = 'Movimentação não encontrada.';
          break;

        case 'protocol.not.found':
          this.translatedCodeMessage = 'Protocolo não encontrado.';
          break;

        case 'protocol.movements.not.found':
          this.translatedCodeMessage = 'Não há movimentações associadas ao protocolo.';
          break;

        case 'record.queue.already':
          this.translatedCodeMessage = 'Já existe uma requisição em processamento.';
          break;

        case 'path.documents.not.found':
          this.translatedCodeMessage = 'O parâmetro para a coleta de documentos não foi encontrado na configuração do coletor.';
          break;

        case 'documents.protocol.not.found':
          this.translatedCodeMessage = 'Não há documentos associados ao protocolo.';
          break;

        case 'invalid.movement.parent.id':
          this.translatedCodeMessage = 'Nenhuma movimentação foi encontrada para o id da movimentação de origem informado.';
          break;

        case 'beneficiary.not.found':
          this.translatedCodeMessage = 'Erro ao buscar o beneficiário pela regra dos treze.';
          break;

        case 'document.not.found':
          this.translatedCodeMessage = 'O documento não foi encontrado pelo Id fornecido.';
          break;

        case 'invalid.file.type':
          this.translatedCodeMessage = 'O tipo de arquivo do layout não é mapeado para o contexto do parâmetro.';
          break;

        case 'sheet.not.found':
          this.translatedCodeMessage = 'A aba informada não foi encontrada.';
          break;

        case 'row.not.found':
          this.translatedCodeMessage = 'A linha informada não foi encontrada.';
          break;

        case 'extension.not.permited':
          this.translatedCodeMessage = 'Extensão informada não permitida.';
          break;

        case 'file.not.found':
          this.translatedCodeMessage = 'Arquivo não encontrado.';
          break;

        case 'layout.not.found':
          this.translatedCodeMessage = 'Não foi possível determinar o layout.';
          break;

        case 'delimiter.not.found':
          this.translatedCodeMessage = 'O delimitador é obrigatório.';
          break;

        case 'sheet.name.not.found':
          this.translatedCodeMessage = 'O nome da planilha é obrigatório.';
          break;

        case 'start.column.not.found':
          this.translatedCodeMessage = 'A coluna inicial é obrigatória.';
          break;

        case 'start.line.not.found':
          this.translatedCodeMessage = 'A linha inicial é obrigatória.';
          break;

        case 'contracts.not.found':
          this.translatedCodeMessage = 'Contratos não encontrados para o grupo financeiro informado.';
          break;

        case 'invalid.movements':
          this.translatedCodeMessage = 'Não é possível enviar o lote porque nele existem movimentações canceladas.';
          break;

        case 'invalid.file':
          this.translatedCodeMessage = 'Arquivo inválido para conversão.';
          break;

        case 'invalid.movement.conditions':
          this.translatedCodeMessage = 'Não existem movimentações nas condições necessárias para o cancelamento.';
          break;

        case 'file.data.status.invalid':
          this.translatedCodeMessage = 'Ao cancelar as movimentações, o protocolo deve estar com o status "Processado".';
          break;

        case 'id.delete.documents.not.found':
          this.translatedCodeMessage = 'Para realizar a exclusão, é necessário informar o ID dos documentos.';
          break;

        case 'id.download.documents.not.found':
          this.translatedCodeMessage = 'Para baixar é necessário informar o ID dos documentos.';
          break;

        case 'documents.not.found':
          this.translatedCodeMessage = 'Nenhum documento foi encontrado para os IDs fornecidos.';
          break;

        case 'extension.not.allowed':
          this.translatedCodeMessage = 'A extensão do arquivo é inválida.';
          break;

        case 'extension.not.found':
          this.translatedCodeMessage = 'O arquivo não possui uma extensão.';
          break;

        case 'is.critized.not.found':
          this.translatedCodeMessage = 'Para fazer a alteração é necessário informar se é ou não uma correção crítica.';
          break;

        case 'contracted.plan.not.found':
          this.translatedCodeMessage = 'Plano contratado do beneficiário não foi encontrado através do ID vinculado.';
          break;

        case 'sdi.movementRecord.reactivation.duplicated':
          this.translatedCodeMessage = 'Já existe uma movimentação cadastrada para o mesmo beneficiário.';
          break;

        case 'movement.record.id.not.found':
          this.translatedCodeMessage = 'Não foi possível localizar a movimentação através do id informado.';
          break;

        case 'is.free.movement.not.found':
          this.translatedCodeMessage = 'Para fazer a alteração é necessário informar se é uma movimentação livre ou não.';
          break;

        case 'contract.id.not.found':
          this.translatedCodeMessage = 'O contrato e/ou subcontrato não foram encontrados através do id informado.';
          break;

        case 'contract.not.found':
          this.translatedCodeMessage = 'Não foi possível encontrar o contrato pelo id fornecido.';
          break;

        case 'invalid.criticize.correction':
          this.translatedCodeMessage = 'A movimentação não se enquadra nos parâmetros permitidos para correção crítica.';
          break;

        case 'batch.config.id.not.found':
          this.translatedCodeMessage = 'Não foi possível encontrar a configuração do lote pelo ID fornecido.';
          break;

        case 'movement.generated.batch':
          this.translatedCodeMessage = 'Não é possível excluir movimentações com lote gerado.';
          break;

        case 'running.batch':
          this.translatedCodeMessage = 'Esta configuração de lote já está em execução.';
          break;

        case 'waiting.running.batch':
          this.translatedCodeMessage = 'Esta configuração de lote já está na fila para processamento.';
          break;

        case 'batch.movement.not.found':
          this.translatedCodeMessage = 'Não foi possível encontrar movimentações no lote';
          break;

        case 'invalid.finalization.movement.conditions':
          this.translatedCodeMessage = 'Não há movimentações nas condições necessárias para finalização.';
          break;

        case 'layout.column.config.not.found':
          this.translatedCodeMessage = 'Coluna tem o campo TAMANHO obrigatório';
          break;

        case 'layout.column.config.size.allowed':
          this.translatedCodeMessage = message && message.length > 0 ? message : 'Coluna tem o campo TAMANHO maior que o permitido';
          break;

        case 'internal.note.delete.not.allowed':
          this.translatedCodeMessage = 'Não é possível apagar as notas de outro usuário';
          break;

        case 'internal.note.update.not.allowed':
          this.translatedCodeMessage = 'Não é possível atualizar as notas de outro usuário';
          break;

        case 'internal.note.is.empty':
          this.translatedCodeMessage = 'A nota não pode estar vazia';
          break;

        case 'internal.note.user.required':
          this.translatedCodeMessage = 'É necessário estar logado!';
          break;

        case 'financialGroup.id.not.found':
          this.translatedCodeMessage = 'Usuário sem permissão aos dados informados.';
          break;

        case 'carrierCriticism.unique.constraint':
          this.translatedCodeMessage = 'Os valores informados de Operadora já existem.';
          break;

        case 'classification.not.found':
          this.translatedCodeMessage = 'A classificação informada não existe.';
          break;

        case 'carrierCriticism.not.found':
          this.translatedCodeMessage = 'A crítica informada não existe.';
          break;

        case 'carrierCriticism.required.fields':
          this.translatedCodeMessage = 'É necessário informar pelo menos um dos seguintes campos: descrição, código da crítica ou mensagem.';
          break;

        case 'carrierCriticism.classificationId.required':
          this.translatedCodeMessage = 'O campo classificação é obrigatório.';
          break;

        case 'carrierCriticism.insuranceCarrierId.required':
          this.translatedCodeMessage = 'O campo operadora é obrigatório.';
          break;

        case 'carrierCriticism.criticismCode.insuranceCarrierId.exists':
          this.translatedCodeMessage = 'Já existe uma crítica cadastrada código de crítica para a operadora informada.';
          break;

        case 'carrierCriticism.description.insuranceCarrierId.exists':
          this.translatedCodeMessage = 'Já existe uma crítica cadastrada com esta descrição para a operadora informada.';
          break;

        case 'carrierCriticism.message.insuranceCarrierId.exists':
          this.translatedCodeMessage = 'Já existe uma crítica cadastrada com esta mensagem para a operadora informada.';
          break;

        case 'movement.record.status.invalid':
          this.translatedCodeMessage = 'Não é possível finalizar a movimentação no status atual.';
          break;
        case 'movement.record.id.not.found':
          this.translatedCodeMessage = 'A movimentação não foi encontrada.';
          break;
        case 'movement.revert.release.not.allowed':
          this.translatedCodeMessage =`A movimentação está em processo de envio no ${message}. Não é possível reverter a liberação.`;
          break
        case 'movement.not.allowed.finish.with.insurance.carrier':
          this.translatedCodeMessage =`Para finalizar esta movimentação é necessário confirmar o envio do ${message}.`;
          break
        case 'invalid.movement.cancel':
          this.translatedCodeMessage = 'Não foi possível cancelar a movimentação pois o lote não se encontra no status de Aguardando Envio ou com erro.'
          break;
        
        case 'pending.movements.is.empty':
          this.translatedCodeMessage = 'Não há nenhuma movimentação pendente para esta ação.'
          break;

        default:
          this.translatedCodeMessage = 'Ocorreu um erro inesperado, tente novamente.';
          break;
      }
    },
    verifyExceptionWithStatusError412(codeMessage) {
      switch (codeMessage) {
        case 'file.data.upload.error':
          this.translatedCodeMessage = 'Erro ao inserir um arquivo no servidor. Tente novamente.';
          break;

        case 'file.data.download.error':
          this.translatedCodeMessage = 'Erro ao baixar um arquivo no servidor. Tente novamente.';
          break;

        default:
          this.translatedCodeMessage = 'Ocorreu um erro inesperado. Entre em contato com a equipe técnica.';
          break;
      }
    },
    verifyExceptionWithStatusError413(codeMessage) {
      switch (codeMessage) {
        case 'file.size.too.big':
          this.translatedCodeMessage = `O arquivo ultrapassa o limite definido de ${this.rules.getMaxFileSizeMB()}.`;
          break;

        default:
          this.translatedCodeMessage = 'Ocorreu um erro inesperado. Entre em contato com a equipe técnica.';
          break;
      }
    },
    verifyExceptionWithStatusError422(codeMessage) {
      switch (codeMessage) {
        case 'contracted.plan.data.not.found':
          this.translatedCodeMessage = 'Não foi possível localizar os dados do plano contratado do beneficiário.';
          break;

        case 'invalid.movement.status':
          this.translatedCodeMessage = 'Para corrigir um movimento, ele deve ter o status INVALID_IN_CARRIER.';
          break;

        default:
          this.translatedCodeMessage = 'Ocorreu um erro inesperado relacionado às regras. Tente novamente ou contate a equipe técnica.';
          break;
      }
    },
    verifyExceptionWithStatusError500(codeMessage) {
      switch (codeMessage) {
        case 'ftp.connect.error':
          this.translatedCodeMessage = 'Ocorreu um erro ao se conectar ao FTP.';
          break;

        case 'ftp.save.file.error':
          this.translatedCodeMessage = 'Ocorreu um erro ao salvar o arquivo no FTP.';
          break;

        case 'server.error.invalid.implantation':
          this.translatedCodeMessage = 'Ocorreu um erro na implantação. Entre em contato com a equipe técnica.';
          break;

        case 'encoding.file.error':
          this.translatedCodeMessage = 'Erro ao codificar arquivo para base 64.';
          break;

        case 'error.trying.zip.files':
          this.translatedCodeMessage = 'Ocorreu um erro ao tentar zipar os arquivos selecionados.';
          break;

        default:
          this.translatedCodeMessage = 'Ocorreu um erro inesperado. Entre em contato com a equipe técnica.';
          break;
      }
    },
  },
};
